<template>
  <iframe
    class="ci-youtube"
    :src="`https://www.youtube.com/embed/${videoId}`"
    frameborder="0"
    allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
    allowfullscreen
  ></iframe>
</template>

<script>
export default {
  name: "CiYoutube",

  props: {
    videoUrl: {
      type: String,
      default: ""
    }
  },

  computed: {
    videoId() {
      if (this.videoUrl) {
        let url = new URL(this.videoUrl);
        let searchParams = new URLSearchParams(url.search);
        return searchParams.get("v") || url.pathname.replace("/", "");
      }
      return null;
    }
  }
};
</script>
