<template>
  <div class="google-maps">
    <iframe
      :src="
        'https://maps.google.com/maps?z=' +
          parseInt(z) +
          '&output=embed&q=' +
          addressValue
      "
      width="100%"
      height="100%"
      frameborder="0"
      style="border:0"
      allowfullscreen
    />
  </div>
</template>

<script>
export default {
  name: "CiGoogleMaps",
  props: {
    address: {
      type: String,
      default: ""
    },
    z: {
      type: [String, Number],
      default: 10
    }
  },

  computed: {
    addressValue() {
      return this.address === "" ? "United State Of America" : this.address;
    }
  }
};
</script>

<style lang="scss">
.google-maps {
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 9 / 16 * 100%;
  }

  &::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both;
  }
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
</style>
