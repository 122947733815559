<template>
  <RdxInfo>
    {{
      $t("stock.vehicle-details.added-by", {
        author: createdBy || "-",
        date: dateFormat(createdAt)
      })
    }}
    <span v-if="updatedAt">
      {{
        $t("stock.vehicle-details.edited", {
          date: dateFormat(updatedAt)
        })
      }}
    </span>
  </RdxInfo>
</template>

<script>
import { RdxInfo } from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiVehicleMetadata",

  components: {
    RdxInfo
  },
  props: {
    createdBy: {
      type: String,
      default: ""
    },
    createdAt: {
      type: String,
      default: ""
    },
    updatedAt: {
      type: String,
      default: ""
    }
  },

  methods: {
    dateFormat(d) {
      return new Date(d).toLocaleDateString(this.locale);
    }
  }
};
</script>
