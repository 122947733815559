<template>
  <div class="ci-card-image__container">
    <img v-if="coverPhoto" class="ci-card-image" :src="imgSrc" />
    <RdxIcon v-else :class="iconClasses" />
    <slot />
  </div>
</template>

<script>
import { RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";
export default {
  name: "CiCardImage",
  components: {
    RdxIcon
  },
  props: {
    coverPhoto: {
      type: String,
      default: ""
    },
    iconSize: {
      type: String,
      default: "38"
    }
  },

  computed: {
    imgSrc() {
      return `${process.env["VUE_APP_API_URL"]}${this.coverPhoto}`;
    },
    iconClasses() {
      return [
        "ci-card-image__empty",
        "ri-camera-off-line",
        `rdx-icon--${this.iconSize}`
      ];
    }
  }
};
</script>

<style lang="scss">
.ci-card-image {
  width: 100%;
  &__container {
    position: relative;
    flex: 0 0 165px;
    border-radius: 4px;
    overflow: hidden;
    background: $grey-4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }
  &__empty {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $grey-6;
  }
}
</style>
