<template>
  <section class="ci-vehicle-features-list">
    <CiHeading
      v-if="Object.keys(standardEquipment).length"
      class="ci-heading--details"
      level="2"
    >
      {{ $t("stock.vehicle-details.standard-equipment") }}
    </CiHeading>
    <CiAccordion
      v-for="(category, i) in standardEquipment"
      :key="`category-standard-${i}`"
      class="ci-vehicle-features-list__accordion"
      :heading="$t(`global.equipment-options.${i}`, capitalizeFirstLetter(i))"
      :items-count="category.length"
    >
      <ul class="ci-vehicle-features-list__accordion-list">
        <li
          v-for="(spec, index) in category"
          :key="`spec-standard-${index}`"
          class="ci-vehicle-features-list__accordion-list-item"
        >
          {{ spec }}
        </li>
      </ul>
    </CiAccordion>
    <CiHeading
      v-if="Object.keys(optionalEquipment).length"
      class="ci-heading--details"
      level="2"
    >
      {{ $t("stock.vehicle-details.optional-equipment") }}
    </CiHeading>
    <CiAccordion
      v-for="(category, i) in optionalEquipment"
      :key="`category-optional-${i}`"
      class="ci-vehicle-features-list__accordion"
      :heading="$t(`global.equipment-options.${i}`, capitalizeFirstLetter(i))"
      :items-count="category.length"
    >
      <ul class="ci-vehicle-features-list__accordion-list">
        <li
          v-for="(spec, index) in category"
          :key="`spec-optional-${index}`"
          class="ci-vehicle-features-list__accordion-list-item"
        >
          {{ spec }}
        </li>
      </ul>
    </CiAccordion>
  </section>
</template>

<script>
import CiHeading from "@/components/atoms/CiHeading";
import CiAccordion from "@/components/molecules/CiAccordion";

export default {
  name: "CiVehicleFeaturesList",

  components: {
    CiHeading,
    CiAccordion
  },
  props: {
    vehicle: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    standardEquipment() {
      let standardFeatures = this.vehicle.features_standard;
      return standardFeatures
        ? this.divideEquipmentIntoCategories(standardFeatures)
        : {};
    },

    optionalEquipment() {
      let optionalFeatures = this.vehicle.features_optional;
      return optionalFeatures
        ? this.divideEquipmentIntoCategories(optionalFeatures)
        : {};
    }
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    listEquipmentCategories(featuresArray) {
      let categories = featuresArray.map(feature => feature.category);
      categories = [...new Set(categories)];
      if (categories.includes(null) || categories.includes(undefined)) {
        categories = categories.filter(item => {
          return item !== null && item !== undefined;
        });
        categories.push("overall");
      }
      return categories;
    },

    divideEquipmentIntoCategories(featuresArrayFromApi) {
      let equipment = {};
      let featuresArray = featuresArrayFromApi;
      let categories = this.listEquipmentCategories(featuresArrayFromApi);
      categories.forEach(category => {
        equipment[category] = [];
      });
      Object.keys(featuresArray).forEach(i => {
        let feature = featuresArray[i];
        if (feature.category === null || feature.category === undefined) {
          equipment["overall"].push(feature.label);
        } else {
          equipment[feature.category].push(feature.label);
        }
      });
      return equipment;
    }
  }
};
</script>

<style lang="scss">
.ci-vehicle-features-list {
  display: block;

  &__accordion {
    margin-bottom: 32px;
  }

  &__accordion-list {
    list-style: none;
    padding-top: 16px;
  }

  &__accordion-list-item {
    &:before {
      content: "-";
    }
  }
}
</style>
