export default {
  name: "GoBackMixin",

  data() {
    return {
      $__routeFrom: null
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => (vm.$__fromRoute = from));
  },

  methods: {
    $__goBack() {
      if (!this.$__fromRoute.name) this.$router.push(this.$__fromRoute);
      else this.$router.back();
    }
  }
};
