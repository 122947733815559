<template>
  <section class="ci-accordion">
    <header class="ci-accordion__header">
      <CiHeading class="ci-accordion__heading" level="3">
        {{ heading }}
      </CiHeading>
      <div class="ci-accordion__button" @click="toggleContentVisibility">
        <span v-if="itemsCount">{{ itemsCount }}</span>
        <RdxIcon :class="iconClasses" />
      </div>
    </header>
    <div class="ci-accordion__content" :style="maxHeight">
      <slot />
    </div>
  </section>
</template>

<script>
import CiHeading from "../atoms/CiHeading";
import { RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiAccordion",

  components: {
    CiHeading,
    RdxIcon
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    itemsCount: {
      type: [String, Number],
      default: ""
    }
  },

  data() {
    return {
      contentVisible: true
    };
  },

  computed: {
    iconClasses() {
      return {
        "ci-accordion__icon": true,
        "rdx-icon--18": true,
        "ri-arrow-up-s-line": this.contentVisible,
        "ri-arrow-down-s-line": !this.contentVisible
      };
    },

    maxHeight() {
      let calculatedHeight = this.contentVisible
        ? this.itemsCount * 21 * 2 + 16
        : 0;
      return `max-height: ${calculatedHeight}px`;
    }
  },

  methods: {
    toggleContentVisibility() {
      this.contentVisible = !this.contentVisible;
    }
  }
};
</script>

<style lang="scss">
.ci-accordion__header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $grey-5;
}

.ci-accordion__heading {
  font-weight: bold;
  color: $grey-9;
}

.ci-accordion__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $grey-7;
}

.ci-accordion__icon {
  margin-left: 14px;
}

.ci-accordion__content {
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}

// .ci-accordion__content--visible {
//   max-height: 2000px;
// }
//
// .ci-accordion__content--hidden {
//   max-height: 0;
// }
</style>
