export default {
  name: "GoogleMapsQueryMixin",

  computed: {
    googleMapsQuery() {
      const ownerData = this.vehicle?.owner?.data;
      if (!ownerData) return "";
      return `${
        ownerData.skip_company_name_in_location ? "" : ownerData.name || ""
      } ${ownerData.city || ""} ${ownerData.address || ""}`.trim();
    }
  }
};
