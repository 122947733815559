<template>
  <div class="ci-slider ">
    <div class="ci-slider__current-img-container" @click="imgClick($event)">
      <img :src="currentImageUrl" class="ci-slider__current-img" />
      <div
        v-if="!singleImageSlider"
        class="ci-slider__button ci-slider__button--prev"
        @click.stop="slideBack"
      >
        <RdxIcon class="ri-arrow-left-s-line rdx-icon--38 rdx-icon--white" />
      </div>
      <div
        v-if="!singleImageSlider"
        class="ci-slider__button ci-slider__button--next"
        @click.stop="slideForward"
      >
        <RdxIcon class="ri-arrow-right-s-line rdx-icon--38 rdx-icon--white" />
      </div>
      <CiBadge v-if="fullscreenAvailable" class="ci-slider__badge">
        {{ $t("global.fullscreen") }}
      </CiBadge>
      <RdxIcon
        v-if="fullscreen"
        class="ci-slider__close ri-close-line rdx-icon--18 rdx-icon--white"
        @click="close"
      />
    </div>
    <div v-if="withThumbnails" class="ci-slider__thumbnails-list">
      <div
        v-for="(img, i) in images"
        :key="`img-${i}`"
        class="ci-slider__thumbnail"
        :class="{ 'ci-slider__thumbnail--active': i === currentImageIndex }"
      >
        <img
          :src="img.thumb"
          class="ci-slider__thumbnail-img"
          @click="selectImage(i)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiBadge from "../atoms/CiBadge";

export default {
  name: "CiSlider",

  components: {
    RdxIcon,
    CiBadge
  },

  props: {
    images: {
      type: Array,
      required: true
    },
    withThumbnails: {
      type: Boolean,
      default: false
    },
    fullscreenAvailable: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    initialImageIndex: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      currentImageIndex: this.initialImageIndex
    };
  },

  computed: {
    currentImageUrl() {
      return this.images[this.currentImageIndex].image;
    },
    singleImageSlider() {
      return this.images.length === 1;
    }
  },

  methods: {
    selectImage(i) {
      this.currentImageIndex = i;
      this.$emit("slide", this.currentImageIndex);
    },
    slideBack() {
      let indexToSlide = this.currentImageIndex - 1;
      if (indexToSlide < 0) indexToSlide += this.images.length;
      this.selectImage(indexToSlide);
    },

    slideForward() {
      this.selectImage((this.currentImageIndex + 1) % this.images.length);
    },
    imgClick(event) {
      if (this.fullscreen) event.stopPropagation();
      this.$emit("img-click");
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.ci-slider {
  &__current-img-container {
    position: relative;
    cursor: pointer;
    padding-top: 56.25%;
    &:hover {
      .ci-slider__button,
      .ci-slider__badge {
        opacity: 1;
      }
    }
  }

  &__button {
    position: absolute;
    top: 0;
    bottom: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }

  &__button--prev {
    padding: 0 33px 0 24px;
    background: linear-gradient(
      90deg,
      rgba(38, 38, 38, 0.3) 0%,
      rgba(38, 38, 38, 0) 100%
    );
  }

  &__button--next {
    right: 0;
    padding: 0 24px 0 33px;
    background: linear-gradient(
      90deg,
      rgba(38, 38, 38, 0) 0%,
      rgba(38, 38, 38, 0.3) 100%
    );
  }

  &__badge {
    position: absolute;
    top: 16px;
    right: 16px;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 24px;
    line-height: 1;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12),
      0px 1px 2px rgba(0, 0, 0, 0.24);
  }

  &__current-img {
    width: 100%;
    max-height: 100vh;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__thumbnails-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  &__thumbnail {
    width: calc(20% - 8px);
    cursor: pointer;
    margin: 0 10px 10px 0;
    &:nth-child(5n) {
      margin-right: 0;
    }
  }

  &__thumbnail-img {
    max-width: 100%;
    display: block;
  }

  &__thumbnail--active {
    filter: brightness(80%);
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 4px solid $blue-6;
    }
  }
}
</style>
