export default {
  name: "PhotosListMixin",

  computed: {
    $__photosList() {
      const { photos } = this.vehicle;
      if (!photos || !photos.data) return [];
      return [...photos.data]
        .sort((a, b) => a.sequence - b.sequence)
        .map(photo => {
          return {
            thumb: process.env["VUE_APP_API_URL"] + photo.small_path,
            image: process.env["VUE_APP_API_URL"] + photo.big_path
          };
        });
    }
  }
};
