<template>
  <section>
    <CiHeading class="ci-heading--details" level="2">
      {{ $t("stock.vehicle-details.images") }}
    </CiHeading>
    <CiSlider
      v-if="images.length"
      :images="images"
      :fullscreen-available="fullscreenAvailable"
      with-thumbnails
      @img-click="openFullscreenGallery"
      @slide="updateSliderIndex($event)"
    />
    <CiCardImage v-else icon-size="60" class="ci-vehicle-images__placeholder" />
  </section>
</template>

<script>
import CiHeading from "@/components/atoms/CiHeading";
import CiSlider from "@/components/molecules/CiSlider";
import CiCardImage from "@/components/molecules/CiCardImage";

export default {
  name: "CiVehicleImages",

  components: {
    CiHeading,
    CiSlider,
    CiCardImage
  },

  props: {
    images: {
      type: Array,
      default: () => []
    },
    fullscreenAvailable: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    openFullscreenGallery() {
      this.$emit("open-fullscreen-gallery");
    },
    updateSliderIndex(i) {
      this.$emit("update-slider-index", i);
    }
  }
};
</script>

<style lang="scss">
.ci-vehicle-images {
  &__placeholder {
    padding-top: 75%;
    margin-bottom: calc(0.75 * (100% - 40px) / 5 + 20px);
  }
}
</style>
