<template>
  <section v-if="attachmentUrl" class="ci-vehicle-attachment">
    <CiHeading class="ci-heading--details" level="2">
      {{ $t("stock.vehicle-details.attachment") }}
    </CiHeading>
    <CiAttachment :href="fullAttachmentUrl" :label="attachmentName" />
  </section>
</template>

<script>
import CiHeading from "@/components/atoms/CiHeading";
import CiAttachment from "@/components/atoms/CiAttachment";

export default {
  name: "CiVehicleAttachment",

  components: {
    CiHeading,
    CiAttachment
  },
  props: {
    attachmentUrl: {
      type: String,
      default: ""
    }
  },

  computed: {
    attachmentName() {
      return this.attachmentUrl
        ? this.attachmentUrl.split("/").slice(-1)[0]
        : "";
    },

    fullAttachmentUrl() {
      return `${process.env["VUE_APP_API_URL"]}${this.attachmentUrl}`;
    }
  }
};
</script>
