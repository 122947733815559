<template>
  <section v-if="description" class="ci-vehicle-description">
    <CiHeading v-if="description" class="ci-heading--details" level="2">
      {{ $t("stock.vehicle-details.description") }}
    </CiHeading>
    <p class="ci-vehicle-details__description">
      <CiSpec :spec="description" />
    </p>
  </section>
</template>

<script>
import CiHeading from "@/components/atoms/CiHeading";
import CiSpec from "@/components/atoms/CiSpec";

export default {
  name: "CiVehicleDescription",

  components: {
    CiHeading,
    CiSpec
  },
  props: {
    description: {
      type: String,
      default: ""
    }
  }
};
</script>
