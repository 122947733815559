import { render, staticRenderFns } from "./CiSlider.vue?vue&type=template&id=63ca445f&"
import script from "./CiSlider.vue?vue&type=script&lang=js&"
export * from "./CiSlider.vue?vue&type=script&lang=js&"
import style0 from "./CiSlider.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports