<template>
  <div v-if="checkIfSpecExists(spec)" :class="classes">
    <slot v-if="$slots.default" />
    <template v-else>{{ spec }}</template>
  </div>
</template>

<script>
export default {
  name: "CiSpec",

  props: {
    spec: {
      type: [String, Number],
      default: undefined
    },
    color: {
      type: String,
      default: ""
    }
  },

  computed: {
    classes() {
      return { "ci-spec": true, [`ci-spec--${this.color}`]: this.color };
    }
  },

  methods: {
    checkIfSpecExists(spec) {
      return spec || spec === 0 || spec === "0";
    }
  }
};
</script>

<style lang="scss">
.ci-spec {
  &--green {
    color: #52c41a;
  }

  &--orange {
    color: #fa8c16;
  }

  &--red {
    color: #cf1322;
  }

  &--light {
    color: $grey-7;
  }

  &--dark {
    color: $grey-9;
  }

  &--12 {
    font-size: 12px;
  }

  &--16 {
    font-size: 16px;
  }

  &--bold {
    font-weight: 600;
  }

  &--inline {
    display: inline-block;
    margin-right: 0.3em;
  }

  &--spacious {
    line-height: 2;
  }
}
</style>
