<template>
  <div class="ci-attachment">
    <RdxIcon :class="classes" />
    <a
      :href="href"
      target="blank"
      rel="noopener noreferrer"
      class="ci-attachment__label"
      >{{ label }}</a
    >
  </div>
</template>

<script>
import { RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiAttachment",

  components: {
    RdxIcon
  },
  props: {
    href: {
      type: String,
      default: "#"
    },
    label: {
      type: String,
      default: "file-name"
    }
  },

  computed: {
    classes() {
      return [
        "ri-attachment-2",
        "rdx-icon--grey",
        "rdx-icon--18",
        "ci-attachment__icon"
      ];
    }
  }
};
</script>

<style lang="scss">
.ci-attachment {
  display: inline-flex;
  padding: 8px 12px 8px 15px;
  background: $grey-2;
  align-items: center;
  margin-bottom: 24px;
}

.ci-attachment__icon {
  margin-right: 8px;
}

.ci-attachment__label {
  color: $blue-6;
}
</style>
