<template>
  <section v-if="googleMapsQuery" class="ci-vehicle-map">
    <CiHeading class="ci-heading--details" level="2">
      {{ $t("stock.vehicle-details.location") }}
    </CiHeading>
    <CiGoogleMaps :address="googleMapsQuery" z="12" />
  </section>
</template>

<script>
import CiHeading from "@/components/atoms/CiHeading";
import CiGoogleMaps from "@/components/CiGoogleMaps/CiGoogleMaps.vue";

export default {
  name: "CiVehicleMap",

  components: {
    CiHeading,
    CiGoogleMaps
  },
  props: {
    googleMapsQuery: {
      type: String,
      default: ""
    }
  }
};
</script>
