<template>
  <section class="vehicle-details-view">
    <div class="vehicle-details-view__header p-3">
      <CiBreadcrumbs
        v-if="isInFullPreview"
        :breadcrumbs="breadcrumbs"
        class="mb-2"
      />

      <div style="display: flex;">
        <RdxButton
          v-if="isInFullPreview"
          rect
          class="ci-action--back"
          @click="$__goBack"
        >
          <RdxIcon class="ri-arrow-left-line rdx-icon--24" />
        </RdxButton>

        <CiHeading level="1" class="ml-2">
          <div>
            {{ vehicle.nameplate_brand_name }} {{ vehicle.nameplate_name }}
          </div>
          <CiVehicleMetadata
            :created-by="vehicle.created_by"
            :created-at="vehicle.created_at"
            :updated-at="vehicle.updated_at"
            class="mt-1 mb-0"
          />
        </CiHeading>

        <div class="vehicle-details-view__actions">
          <RdxDropdown
            v-tooltip="$t('stock.vehicle-details.options')"
            side="right"
            class="mr-3"
          >
            <RdxDropdownItem
              @click="
                navigateTo({
                  name: 'CarsInventoryVehicleDuplicate',
                  params: { id: $route.params.id }
                })
              "
            >
              <RdxIcon class="ri-file-copy-line rdx-icon--16" />
              <span>
                {{ $t("global.duplicate") }}
              </span>
            </RdxDropdownItem>
            <RdxDropdownItem @click="openDeleteModal()">
              <RdxIcon class="color-red ri-delete-bin-6-line rdx-icon--16" />
              <span class="color-red">
                {{ $t("global.delete") }}
              </span>
            </RdxDropdownItem>
          </RdxDropdown>

          <RdxButton
            outline
            :to="{
              name: 'CarsInventoryVehicleEdit',
              params: { id: $route.params.id }
            }"
          >
            {{ $t("stock.vehicle-details.edit-details-button") }}
          </RdxButton>
        </div>
      </div>
    </div>

    <div class="vehicle-details-view__inner p-4">
      <div class="ci-vehicle-details__main pb-5">
        <div class="ci-vehicle-details__column">
          <CiVehicleDataTable :vehicle="vehicle" />
        </div>
        <div class="ci-vehicle-details__column">
          <CiVehicleImages
            :images="$__photosList"
            fullscreen-available
            class="mb-4"
            @open-fullscreen-gallery="openFullscreenGallery"
            @update-slider-index="updateSliderIndex($event)"
          />
          <CiVehicleVideo
            v-if="vehicle.video_url"
            :video-url="vehicle.video_url"
            class="mb-4"
          />
          <CiVehicleDescription
            v-if="vehicle.long_description"
            :description="vehicle.long_description"
            class="mb-4"
          />
          <CiVehicleFeaturesList :vehicle="vehicle" />
          <CiVehicleAttachment
            v-if="vehicle.attachment_url"
            :attachment-url="vehicle.attachment_url"
          />
          <CiVehicleMap :google-maps-query="googleMapsQuery" />
        </div>
        <div
          v-if="fullscreenGalleryOpened"
          class="ci-vehicle-details__fullscreen-gallery-overlay"
          @click="closeFullscreenGallery"
        >
          <CiSlider
            :images="$__photosList"
            fullscreen
            :initial-image-index="sliderImageIndex"
            class="ci-vehicle-details__fullscreen-gallery"
            @close="closeFullscreenGallery"
          />
        </div>
      </div>
    </div>
    <CiDeleteModal
      v-if="deleteModalOpened"
      class="ci-vehicle-details__delete-modal"
      :heading="deleteModalData.heading"
      :paragraph="deleteModalData.paragraph"
      :delete-text="$t('stock.remove-modal.confirm-action')"
      :cancel-text="$t('global.cancel')"
      @cancel="closeDeleteModal"
      @delete="deleteVehicle"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import goBackMixin from "./go-back-mixin";
import GoogleMapsQueryMixin from "@/mixins/google-maps-query-mixin";
import PhotosListMixin from "@/mixins/photos-list-mixin";

import {
  RdxIcon,
  RdxButton,
  RdxDropdown,
  RdxDropdownItem
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiBreadcrumbs from "@/components/atoms/CiBreadcrumbs.vue";
import CiHeading from "@/components/atoms/CiHeading";
import CiSlider from "@/components/molecules/CiSlider";
import CiDeleteModal from "@/components/CiDeleteModal/CiDeleteModal";
import CiVehicleDataTable from "@/components/CiVehicleDataTable/CiVehicleDataTable";
import CiVehicleFeaturesList from "@/components/CiVehicleFeaturesList/CiVehicleFeaturesList";
import CiVehicleAttachment from "@/components/CiVehicleAttachment/CiVehicleAttachment";
import CiVehicleMetadata from "@/components/CiVehicleMetadata/CiVehicleMetadata";
import CiVehicleImages from "@/components/CiVehicleImages/CiVehicleImages";
import CiVehicleDescription from "@/components/CiVehicleDescription/CiVehicleDescription";
import CiVehicleVideo from "@/components/CiVehicleVideo/CiVehicleVideo";
import CiVehicleMap from "@/components/CiVehicleMap/CiVehicleMap";
import { deserialise } from "kitsu-core";

export default {
  name: "VehicleDetailsView",

  components: {
    RdxIcon,
    RdxButton,
    RdxDropdown,
    RdxDropdownItem,
    CiBreadcrumbs,
    CiHeading,
    CiSlider,
    CiDeleteModal,
    CiVehicleDataTable,
    CiVehicleFeaturesList,
    CiVehicleAttachment,
    CiVehicleMetadata,
    CiVehicleImages,
    CiVehicleDescription,
    CiVehicleVideo,
    CiVehicleMap
  },

  mixins: [goBackMixin, GoogleMapsQueryMixin, PhotosListMixin],

  data() {
    return {
      fullscreenGalleryOpened: false,
      vehicle: {},
      sliderImageIndex: 0,
      deleteModalOpened: false
    };
  },

  computed: {
    isInFullPreview() {
      return this.$route.name === "CarsInventoryVehicleDetailsPreview";
    },
    breadcrumbs() {
      return [
        { text: "CarsInventory", link: { name: "CarsInventoryStock" } },
        {
          text: this.$t("navbar.stock"),
          link: { name: "CarsInventoryStock" }
        }
        // {
        //   text: `${this.vehicle.nameplate_brand_name || "..."}
        //   ${this.vehicle.nameplate_name || ""}`
        // }
      ];
    },

    deleteModalData() {
      return {
        heading: this.$t("stock.remove-modal.header", {}, 1),
        paragraph:
          this.$t("stock.remove-modal.description", { vehiclesNumber: 1 }, 1) +
          " " +
          this.$t("stock.remove-modal.note")
      };
    },

    ...mapGetters({
      locale: "location/getLocale"
    })
  },

  created() {
    this.getVehicle(this.$route.params.id);
  },

  methods: {
    getVehicle(id) {
      this.$http.get(`vehicles/${id}?include=photos,owner`).then(response => {
        deserialise(response.data);
        this.vehicle = response.data.data;
        this.loading = false;
      });
    },

    navigateTo(route) {
      if (route.name !== this.$route.name) {
        this.$router.push(route);
      }
    },

    openFullscreenGallery() {
      this.fullscreenGalleryOpened = true;
    },
    closeFullscreenGallery() {
      this.fullscreenGalleryOpened = false;
    },
    updateSliderIndex(index) {
      this.sliderImageIndex = index;
    },
    openDeleteModal(vehiclesList) {
      this.vehiclesToDelete = vehiclesList;
      this.deleteModalOpened = true;
    },
    closeDeleteModal() {
      this.deleteModalOpened = false;
      this.vehiclesToDelete = [];
    },
    deleteVehicle() {
      this.$http.delete(`vehicles/${this.$route.params.id}`).then(() => {
        this.$router.push({ name: "CarsInventoryStock" });
        this.$toast.success(this.$t("stock.vehicle-deleted-successfull"));
      });
    }
  }
};
</script>

<style lang="scss">
.ci-action--back {
  color: $grey-10;
  &:active:not(:disabled),
  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: $blue-6;
    border-color: transparent;
    &:before {
      opacity: 0;
    }
  }
}
.vehicle-details-view {
  position: relative;

  &__inner {
    display: block;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 150px);
    overflow: auto;
  }

  &__header {
    border-bottom: 1px solid $grey-5;
    padding: 17px 24px 21px;
    position: relative;
  }

  &__actions {
    display: flex;
    position: absolute;
    right: 1.5rem;
    bottom: 1rem;
  }
}

.ci-vehicle-details {
  &__main {
    max-width: 1000px;
    margin: 0 auto;
  }

  &__main {
    display: flex;
  }

  &__column {
    width: 57%;
    &:first-child {
      width: 43%;
    }
  }

  &__fullscreen-gallery {
    width: 100%;
    max-height: 90vh;
    max-width: calc(4 / 3 * 90vh);
  }

  &__fullscreen-gallery-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    background: rgba(38, 38, 38, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
  }

  &__delete-modal {
    position: fixed;
  }
}

.color-red {
  color: $red-5 !important;
}
</style>
