<template>
  <section v-if="videoUrl" class="ci-vehicle-video">
    <CiHeading v-if="videoUrl" class="ci-heading--details" level="2">
      {{ $t("stock.vehicle-details.video") }}
    </CiHeading>
    <CiYoutube
      v-if="videoUrl"
      class="ci-vehicle-video__player"
      :video-url="videoUrl"
    />
  </section>
</template>

<script>
import CiHeading from "@/components/atoms/CiHeading";
import CiYoutube from "@/components/molecules/CiYoutube";

export default {
  name: "CiVehicleVideo",

  components: {
    CiHeading,
    CiYoutube
  },
  props: {
    videoUrl: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
.ci-vehicle-video {
  &__player {
    width: calc(40% - 8px);
    position: relative;
  }
}
</style>
