<template>
  <section
    class="ci-vehicle-data-table__container"
    :class="{ 'ci-vehicle-data-table__container--columns': columns }"
  >
    <table
      class="ci-vehicle-data-table"
      :class="{ 'ci-vehicle-data-table--columns': columns }"
    >
      <!-- Prices -->
      <th v-if="vehicleHasPrice">
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.vehicle-details.price") }}
        </CiHeading>
      </th>
      <tr v-if="vehicle.sale_price">
        <td>{{ $t("stock.vehicle-details.sale-price") }}</td>
        <td>
          <CiSpec
            :spec="
              $format.price(
                vehicle.sale_price,
                vehicle.owner_locale,
                vehicle.currency
              )
            "
            color="dark"
            class="ci-spec--16 ci-spec--bold"
          />
        </td>
      </tr>
      <tr v-if="vehicle.msrp_price">
        <td>{{ $t("stock.vehicle-details.price") }}</td>
        <td>
          <CiSpec
            :spec="
              $format.price(
                vehicle.msrp_price,
                vehicle.owner_locale,
                vehicle.currency
              )
            "
            color="dark"
            class="ci-spec--16"
          />
        </td>
      </tr>
      <tr v-if="vehicle.monthly_price">
        <td>{{ $t("stock.vehicle-details.lease") }}</td>
        <td>
          <CiSpec
            :spec="vehicle.monthly_price"
            color="dark"
            class="ci-spec--16 ci-spec--bold"
          >
            {{
              $format.price(
                vehicle.monthly_price,
                vehicle.owner_locale,
                vehicle.currency
              )
            }}
            <span class="ci-vehicle-card__unit"
              >/{{ $t("stock.monthly-abbreviation") }}</span
            >
          </CiSpec>
        </td>
      </tr>
      <tr v-if="vehicle.price_hidden">
        <td colspan="2">
          <div class="ci-vehicle-data-table__indicator">
            <RdxIcon
              class="ri-eye-off-line ci-vehicle-data-table__indicator-icon"
            />
            <span>
              {{ $t("stock.vehicle-details.price-hidden-description") }}
            </span>
          </div>
        </td>
      </tr>

      <!-- Details -->
      <th>
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.vehicle-details.details") }}
        </CiHeading>
      </th>
      <tr>
        <td>
          {{ $t("stock.vehicle-details.version") }}
        </td>
        <td><CiSpec :spec="vehicle.version" color="dark" /></td>
      </tr>
      <tr>
        <td>
          {{ $t("stock.vehicle-details.drivetrain") }}
        </td>
        <td><CiSpec :spec="vehicle.drivetrain" color="dark" /></td>
      </tr>
      <tr v-if="vehicle.state">
        <td>{{ $t("stock.vehicle-details.state") }}</td>
        <td>
          <CiSpec :spec="$t(`global.states.${vehicle.state}`)" color="dark" />
        </td>
      </tr>
      <tr v-if="vehicle.status">
        <td>{{ $t("stock.vehicle-details.status") }}</td>
        <td>
          <CiSpec
            :spec="$t(`global.availabilities.${vehicle.status}`)"
            :color="statusTextColor"
            class="ci-spec--bold"
          />
        </td>
      </tr>
      <tr v-if="vehicle.delivery_date">
        <td>{{ $t("stock.vehicle-details.availability_date") }}</td>
        <td>
          <CiSpec :spec="vehicle.delivery_date" color="dark">
            {{ vehicle.delivery_date }}
          </CiSpec>
        </td>
      </tr>

      <tr>
        <td>
          {{ $t("stock.vehicle-details.vin") }}
        </td>
        <td><CiSpec :spec="vehicle.vin" color="dark" /></td>
      </tr>
    </table>

    <table
      class="ci-vehicle-data-table"
      :class="{ 'ci-vehicle-data-table--columns': columns }"
    >
      <!-- Specifications -->
      <th>
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.vehicle-details.specifications") }}
        </CiHeading>
      </th>
      <tr>
        <td>
          {{ $t("stock.vehicle-details.production-year") }}
        </td>
        <td><CiSpec :spec="vehicle.production_year" color="dark" /></td>
      </tr>

      <!-- mileage -->
      <tr>
        <td>{{ $t("stock.vehicle-details.mileage") }}</td>
        <td>
          <CiSpec
            class="ci-spec--inline"
            :spec="vehicle.mileage"
            color="dark"
          />
          <CiSpec
            v-if="vehicle.mileage_unit"
            class="ci-spec--inline"
            :spec="$t(`global.mileage_units.${vehicle.mileage_unit}`)"
            color="dark"
          />
        </td>
      </tr>

      <!-- engine type -->
      <tr v-if="vehicle.engine_type">
        <td>{{ $t("stock.vehicle-details.engine") }}</td>
        <td>
          <CiSpec
            v-for="engine in vehicle.engine_type"
            :key="engine"
            :spec="$t(`global.engines.${engine}`)"
            data-cy="ci-vehicle-data-table-engine"
          />
        </td>
      </tr>

      <!-- transmission -->
      <tr v-if="vehicle.transmission">
        <td>{{ $t("stock.vehicle-details.transmission") }}</td>
        <td>
          <CiSpec
            :spec="$t(`global.transmissions.${vehicle.transmission}`)"
            color="dark"
          />
        </td>
      </tr>

      <!-- battery capacity -->
      <tr v-if="vehicle.battery_capacity">
        <td>{{ $t("stock.vehicle-details.battery-capacity") }}</td>
        <td data-cy="battery-capacity">
          <CiSpec
            class="ci-spec--inline"
            :spec="vehicle.battery_capacity"
            color="dark"
          />
          <CiSpec class="ci-spec--inline" spec="kWh" color="dark" />
        </td>
      </tr>

      <!-- engine capacity -->
      <tr>
        <td>{{ $t("stock.vehicle-details.engine-capacity") }}</td>
        <td>
          <CiSpec
            class="ci-spec--inline"
            :spec="vehicle.engine_capacity_normalized"
            color="dark"
          />
          <CiSpec
            class="ci-spec--inline"
            :spec="computedCapacityUnit"
            color="dark"
          />
        </td>
      </tr>

      <!-- power -->
      <tr>
        <td>{{ $t("stock.vehicle-details.power") }}</td>
        <td>
          <CiSpec
            class="ci-spec--inline"
            :spec="vehicle.power"
            color="dark"
          /><CiSpec
            v-if="vehicle.power_unit"
            class="ci-spec--inline"
            :spec="$t(`global.power_unit.${vehicle.power_unit}`)"
            color="dark"
          />
        </td>
      </tr>

      <!-- exterior colour -->
      <tr>
        <td>
          {{ $t("stock.vehicle-details.color") }}
        </td>
        <td><CiSpec :spec="vehicle.color_name" color="dark" /></td>
      </tr>

      <!-- interior colour -->
      <tr>
        <td>
          {{ $t("stock.vehicle-details.interior-colour") }}
        </td>
        <td><CiSpec :spec="vehicle.interior_color" color="dark" /></td>
      </tr>

      <!-- owner name -->
      <tr v-if="vehicle.owner_name">
        <td>
          {{ $t("stock.vehicle-details.dealer-name") }}
        </td>
        <td><CiSpec :spec="vehicle.owner_name" color="dark" /></td>
      </tr>

      <!-- owner city -->
      <tr v-if="vehicle.owner_city">
        <td>
          {{ $t("stock.vehicle-details.location") }}
        </td>
        <td><CiSpec :spec="vehicle.owner_city" color="dark" /></td>
      </tr>
    </table>
  </section>
</template>

<script>
import CiHeading from "@/components/atoms/CiHeading";
import CiSpec from "@/components/atoms/CiSpec";
import { RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiVehicleDataTable",

  components: {
    CiHeading,
    CiSpec,
    RdxIcon
  },

  props: {
    vehicle: {
      type: Object,
      default: () => ({})
    },
    columns: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    vehicleHasPrice() {
      return (
        this.vehicle.sale_price ||
        this.vehicle.msrp_price ||
        this.vehicle.monthly_price
      );
    },

    statusTextColor() {
      if (this.vehicle.status === "stock") return "green";
      if (this.vehicle.status === "reserved") return "orange";
      if (this.vehicle.status === "sold") return "red";
      return "dark";
    },

    computedCapacityUnit() {
      return this.vehicle.engine_capacity_unit
        ? this.$t(
            `global.engine_capacity_unit.${this.vehicle.engine_capacity_unit}`
          )
        : null;
    }
  }
};
</script>

<style lang="scss">
.ci-vehicle-data-table {
  margin-bottom: 24px;
  &--columns {
    flex: 0 0 50%;
  }
  &__container {
    max-width: 400px;
    &--columns {
      display: flex;
      max-width: 800px;
    }
  }
  th {
    text-align: left;
  }
  tr + th {
    padding-top: 24px;
  }
  td {
    padding: 4px 0;
    &:first-child {
      color: $grey-7;
      padding-right: 32px;
    }
  }
  .ci-vehicle-data-table__indicator:first-child {
    background: $grey-3;
    color: $grey-9;
    padding: 12px;
    display: flex;
  }
  &__indicator-icon {
    font-size: 20px;
    color: $grey-7;
    margin-right: 9px;
    margin-top: -3px;
  }
}
</style>
